<template>
    <div>
        <AAlert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <ARow :gutter="[8, 8]">
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterATSalesmanCorsalesSalesman
                    style="width:100%;"
                    v-model:value="state.params.salesman"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterToko
                    style="width:100%;"
                    mode="multiple"
                    v-model:value="state.params.toko"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterRegional
                    style="width:100%;"
                    v-model:value="state.params.region"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterDistributorCurah
                    style="width:100%;"
                    v-model:region="state.params.region"
                    v-model:value="state.params.distributor"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <ASelect
                    v-model:value="state.params.active"
                    allow-clear
                    :options="state.statusLists"
                    placeholder="Pilih Status"
                    style="width:100%;"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <AButton
                    type="primary"
                    title="cari"
                    @click="fetchDataList"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </ACol>
        </ARow>

        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12"></div>
            <div class="col-lg-6 col-md-12 text-right">
                <ASpace>
                    <ATooltip
                        v-if="hasPermissions(['users-salesman-corsales.store'])"
                        title="Tambahkan">
                        <AButton
                            type="primary"
                            @click="btnForm">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                        </AButton>
                    </ATooltip>
                    <ATooltip title="Download excel">
                        <DownloadExcel
                            :url="state.endpoint.current"
                            :params="queryParams()"
                            namefile="Master-User-Salesman"
                            @errors="errorMessage"/>
                    </ATooltip>
                </ASpace>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <!-- role TSO akan menampilkan centang untuk approve and reject -->
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                row-key="id"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">

                <template #status="{ text }">
                  <a-tag v-if="text === 'Aktif'" color="#108ee9">Aktif</a-tag>
                  <a-tag v-else color="grey">Tidak Aktif</a-tag>
                </template>

                <template #action="{ record }">
                    <ASpace>
                        <ATooltip
                            v-if="hasPermissions(['users-salesman-corsales.distributors-index'])"
                            title="List Distributor">
                            <AButton
                                class="button"
                                size="small"
                                :disabled="!record.active && record.approval_action"
                                @click="btnDistributorList(record)">
                                <i class="fa fa-calendar-check-o" />
                            </AButton>
                        </ATooltip>
                        <ATooltip
                            v-if="hasPermissions(['users-salesman-corsales.update'])"
                            title="Edit">
                            <AButton
                                class="button"
                                size="small"
                                :disabled="!record.active && record.approval_action"
                                @click="btnForm(record)">
                                <i class="fe fe-edit" />
                            </AButton>
                        </ATooltip>
                        <ATooltip
                            v-if="hasPermissions(['users-salesman-corsales.show'])"
                            title="Details">
                            <AButton
                                class="button"
                                size="small"
                                @click="btnForm(record, true)">
                                <i class="fe fe-eye" />
                            </AButton>
                        </ATooltip>
                    </ASpace>
                </template>

            </MdTable>
        </div>

        <!-- form -->
        <FormCreateOrUpdate
            v-if="visibleFormModal"
            v-model:visible="visibleFormModal"
            v-model:item="visibleFormItemModal"
            @success="fetchDataList"/>

        <!-- detail -->
        <DistributorList
            v-if="visibleDistributorListModal"
            v-model:visible="visibleDistributorListModal"
            v-model:item="visibleDistributorListItemModal"/>
            
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import apiClient from '@/services/axios'
import FilterATSalesmanCorsalesSalesman from '@/components/filter/FilterATSalesmanCorsalesSalesman'
import FilterToko from '@/components/filter/FilterToko'
import FilterDistributorCurah from '@/components/filter/FilterDistributorCurah'
import FilterRegional from '@/components/filter/FilterRegional'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import FormCreateOrUpdate from './FormCreateOrUpdate'
import DistributorList from './DistributorList'
import { includes } from 'lodash'
import {
    hasPermissions,
} from '@/helpers'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/queryparams'

export default defineComponent({
    components: {
        FormCreateOrUpdate,
        DistributorList,
        DownloadExcel,
        FilterATSalesmanCorsalesSalesman,
        FilterToko,
        FilterDistributorCurah,
        FilterRegional,
    },
    setup() {
        const errorMessage = ref()
        const state = reactive({
            columns: [
                {
                    title: 'ID',
                    dataIndex: 'id',
                },
                {
                    title: 'Nomor KTP/NPWP',
                    dataIndex: 'nopeg',
                },
                {
                    title: 'Nama Lengkap',
                    dataIndex: 'fullname',
                },
                {
                    title: 'Username',
                    dataIndex: 'username',
                },
                {
                    title: 'Email',
                    dataIndex: 'email',
                },
                {
                    title: 'Telepon',
                    dataIndex: 'phone',
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    slots: { customRender: 'status' },
                },
                {
                    title: 'Nama Distributor',
                    dataIndex: 'distributors',
                },
                {
                    title: 'Status Approval',
                    dataIndex: 'status_approval',
                },
                {
                    title: 'Approval Dari TSO',
                    dataIndex: 'tso_name',
                },
                {
                    title: 'Action',
                    dataIndex: 'action',
                    slots: { customRender: 'action' },
                },
            ],
            endpoint: {
                current: '/api/users-salesman-corsales',
            },
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            params: extractQueryParams({
                q: '',
                salesman: [],
                toko: [],
                region: [],
                distributor: [],
                active: null,
                page: 1,
                "per-page": 10,
            }),
            statusLists: [
                {
                    label: 'Aktif',
                    value: 1,
                },
                {
                    label: 'Nonaktif',
                    value: 0,
                },
            ],
        })

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataList()
        }

        const queryParams = () => {
            let params = state.params

            return updateUrlWithQuery(params)
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true

            apiClient
                .get(state.endpoint.current, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    const { items, _meta } = data

                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        // handle form
        const visibleFormModal = ref(false);
        const visibleFormItemModal = ref(null);

        const btnForm = (item = null, readOnly = false) => {
            visibleFormModal.value = true
            visibleFormItemModal.value = {
                ...item,
                readOnly,
            }
        }

        // handle distributor list
        const visibleDistributorListModal = ref(false);
        const visibleDistributorListItemModal = ref(null);

        const btnDistributorList = (item = null) => {
            visibleDistributorListModal.value = true
            visibleDistributorListItemModal.value = item
        }

        onMounted(() => {
            fetchDataList()

            // delete column action ketika semua permission tidak ada
            if (!hasPermissions(['users-salesman-corsales.update', 'users-salesman-corsales.show', 'users-salesman-corsales.distributors-index'])) {
                state.columns = state.columns.filter(item => {
                    if (!includes(['action'], item.key)) {
                        return item
                    }
                })
            }
        })

        return {
            fetchDataList,
            handleTableChange,
            state,
            errorMessage,
            visibleFormModal,
            visibleFormItemModal,
            btnForm,
            visibleDistributorListModal,
            visibleDistributorListItemModal,
            btnDistributorList,
            queryParams,
            // only variable role
            hasPermissions,
        }
    },
})
</script>
