<template>
    <a-modal
        :visible="visible"
        :title="state.title"
        @cancel="handleModalCancel"
        width="70%"
        :destroy-on-close="true"
        :footer="null">
    
        <AForm
            ref="formRef"
            class="myform"
            :model="form"
            :rules="state.rules"
            @keydown="form.onKeydown($event)"
            @finish="handleOk"
            :wrapper-col="{ span: 14 }"
            :scroll-to-first-error="true"
            novalidate>

            <!-- no pegawai -->
            <a-row class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        :label="renderCustomLabel('No KTP/NPWP', state.helps.nopeg)"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="nopeg"
                        has-feedback>
                        <AInput
                            placeholder="no pegawai"
                            :disabled="item.readOnly"
                            v-model:value="form.nopeg" />
                    </a-form-item>
                </a-col>
            </a-row>

            <!-- nama pegawai -->
            <a-row class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        :label="renderCustomLabel('Nama Pegawai', state.helps.fullname)"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="fullname"
                        has-feedback>
                        <AInput
                            placeholder="nama pegawai"
                            :disabled="item.readOnly"
                            v-model:value="form.fullname" />
                    </a-form-item>
                </a-col>
            </a-row>

            <!-- username -->
            <a-row class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        :label="renderCustomLabel('Username', state.helps.username)"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="username"
                        has-feedback>
                        <AInput
                            placeholder="username"
                            :disabled="item.readOnly"
                            v-model:value="form.username" />
                    </a-form-item>
                </a-col>
            </a-row>

            <!-- email pegawai -->
            <a-row class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        :label="renderCustomLabel('Email', state.helps.email)"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="email"
                        has-feedback>
                        <AInput
                            placeholder="email pegawai"
                            type="email"
                            :disabled="item.readOnly"
                            v-model:value="form.email" />
                    </a-form-item>
                </a-col>
            </a-row>

            <!-- kata sandi -->
            <a-row v-if="!form.id" class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        :label="renderCustomLabel('Kata Sandi', state.helps.password)"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="password"
                        has-feedback>
                        <AInputPassword
                            placeholder="kata sandi"
                            :disabled="item.readOnly"
                            v-model:value="form.password" />
                    </a-form-item>
                </a-col>
            </a-row>

            <!-- konfirmasi kata sandi -->
            <a-row v-if="!form.id" class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        :label="renderCustomLabel('Konfirmasi Kata Sandi', state.helps.retype_password)"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="retype_password"
                        has-feedback>
                        <AInputPassword
                            placeholder="konfirmasi kata sandi"
                            :disabled="item.readOnly"
                            v-model:value="form.retype_password" />
                    </a-form-item>
                </a-col>
            </a-row>

            <!-- phone -->
            <a-row class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        :label="renderCustomLabel('Nomor Telepon', state.helps.phone)"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="phone"
                        has-feedback>
                        <AInput
                            addon-before="+62"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                            pattern="^[1-9][0-9]*$"
                            oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value=''"
                            placeholder="Ex : 81xxxxxxxxx"
                            v-model:value="form.phone"
                            :disabled="item.readOnly"
                            min="0"
                            maxlength="13" />
                    </a-form-item>
                </a-col>
            </a-row>

            <!-- distributor -->
            <a-row
                v-if="!form.id && hasRoles([ROLE_ADMIN_BK, ROLE_TSO, ROLE_TIM_CRM, ROLE_HELPDESK])"
                class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        :label="renderCustomLabel('Distributor', state.helps.distributor)"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="distributor"
                        has-feedback>
                        <FilterDistributorCurah
                            style="width:100%"
                            :disabled="item.readOnly"
                            v-model:value="form.distributor"
                            show-search/>
                    </a-form-item>
                </a-col>
            </a-row>

            <a-row
                v-if="hasRoles([ROLE_ADMIN_BK, ROLE_TSO, ROLE_TIM_CRM, ROLE_HELPDESK])"
                class="form-row">
                <a-col :sm="24">
                    <AFormItem
                        :label="renderCustomLabel('Status', state.helps.status)"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="active"
                        has-feedback>
                        <ARadioGroup
                            v-model:value="form.active"
                            :disabled="item.readOnly || hasRoles([ROLE_TSO])"
                            style="width:100%">
                            <a-radio :value="1">Aktif</a-radio>
                            <a-radio :value="0">Non Aktif</a-radio>
                        </ARadioGroup>
                    </AFormItem>
                </a-col>
            </a-row>

            <a-row
                v-if="!item.readOnly"
                class="form-row"
                type="flex"
                justify="end">
                <a-col>
                    <a-form-item>
                        <a-button
                            type="primary"
                            html-type="submit"
                            :loading="form.busy"
                            :disabled="form.busy">{{
                            !form.id ? 'Simpan' : 'Perbarui'
                        }}</a-button>
                    </a-form-item>
                </a-col>
            </a-row>
        </AForm>

        <div v-if="form.errors.any()">
            <ASpace>
                <AAlert
                    v-for="(item, index) in form.errors.all()"
                    type="error"
                    :key="index"
                    :message="item.message"
                    banner
                    closable
                    @close="form.clear()" />
            </ASpace>
        </div>
    </a-modal>
</template>
<script>
import {
    defineComponent,
    onMounted,
    reactive,
    ref,
} from 'vue'
import Form from 'vform'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import FilterDistributorCurah from '@/components/filter/FilterDistributorCurah'
import LabelWithTooltip from '@/components/Molecules/LabelWithTooltip'
import {
    hasRoles,
    ROLE_ADMIN_BK,
    ROLE_TSO,
    ROLE_TIM_CRM,
    ROLE_DISTRIBUTOR,
    ROLE_HELPDESK,
} from '@/helpers'
import { debounce } from 'lodash'

export default defineComponent({
    components: {
        FilterDistributorCurah,
    },
    props: {
        visible: [Boolean],
        item: {
            type: Object,
            default: () => ({
                id: null,
                readOnly: false,
            }),
        },
    },
    emits: ['handleOkConversionPoint', 'update:visible'],
    setup(props, { emit }) {
        const form = reactive(new Form({
            id: props.item.id,
            nopeg: null,
            fullname: null,
            username: null,
            email: null,
            password: null,
            retype_password: null,
            phone: null,
            active: null,
            distributor: [],
            distributors: [],
        }))

        const formRef = ref()

        const state = reactive({
            title:'Details - Master Data Salesman',
            endpoint: {
                current: `/api/users-salesman-corsales`,
                validate_nopeg: '/api/users-validation/nopeg',
                validate_username: '/api/users-validation/username',
                validate_email: '/api/users-validation/email',
            },
            data: [],
            page: 1,
            per_page: 1,
            total: 0,
            loading: false,
            isTransferSalesman: false,
            rules: {
                nopeg: [
                    {
                        required: true,
                        message: "No KTP/NPWP tidak boleh kosong!",
                    },
                    {
                        async validator(_, value) {
                            return validateNopegAvailability(value)
                        },
                    },
                ],
                fullname: [
                    {
                        required: true,
                        message: "Nama pegawai tidak boleh kosong!",
                    },
                ],
                username: [
                    {
                        required: true,
                        message: "Username tidak boleh kosong!",
                    },
                    {
                        pattern: /^[a-z][a-z0-9_]*$/,
                        message: "Username harus dimulai dengan huruf dan hanya boleh berisi huruf kecil, angka, atau garis bawah!",
                    },
                    {
                        pattern: /^(?!.*__)[a-z0-9_]+$/,
                        message: "Username tidak boleh memiliki garis bawah berturut-turut!",
                    },
                    {
                        async validator(_, value) {
                            return validateUsernameAvailability(value)
                        },
                    },
                ],
                email: [
                    {
                        required: true,
                        message: "Email tidak boleh kosong!",
                    },
                    {
                        async validator(_, value) {
                            return validateEmailAvailability(value)
                        },
                    },
                ],
                password: [
                    {
                        required: true,
                        message: "Kata sandi tidak boleh kosong!",
                    },
                    {
                        pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/,
                        message: "Kata sandi minimal 8 karakter, termasuk huruf besar, huruf kecil, angka, dan simbol!",
                    },
                ],
                retype_password: [
                    {
                        required: true,
                        message: "Konfirmasi kata sandi tidak boleh kosong!",
                    },
                    {
                        validator: async (rule, value) => {
                            if (value !== form.password) {
                                return Promise.reject('Konfirmasi kata sandi harus sama dengan kata sandi!')
                            } else {
                                return Promise.resolve()
                            }
                        },
                    },
                ],
                phone: [
                    {
                        required: true,
                        message: 'Nomor Telepon tidak boleh kosong!',
                    },
                    {
                        max: 12,
                        min: 0,
                        message: 'Maximal nomor telepon 12 angka!',
                    },
                    {
                        pattern: /^[1-9][0-9]*$/,
                        message: "Masukkan nomor telepon aktif tanpa angka '0' di awal dan hanya boleh memuat angka!",
                    },
                ],
                distributor: [
                    {
                        required: true,
                        message: "Distributor tidak boleh kosong!",
                    },
                ],
                active: [
                    {
                        required: true,
                        message: "Status tidak boleh kosong!",
                    },
                ],
            },
            helps: {
                nopeg: 'Masukkan nomor KTP atau NPWP yang valid.',
                fullname: 'Masukkan nama lengkap sesuai identitas.',
                username: 'Masukkan username dengan menggunakan Nama. Jika username yang dimasukkan sudah terpakai, tambahkan kombinasi angka di akhir nama.',
                email: 'Masukkan alamat email aktif.',
                password: 'Masukkan kata sandi minimal 8 karakter, termasuk huruf besar, huruf kecil, angka dan simbol.',
                retype_password: 'Masukkan ulang kata sandi untuk konfirmasi.',
                phone: "Masukkan nomor telepon aktif tanpa angka '0' di awal.",
                distributor: 'Pilih Distributor yang akan di mappingkan dengan salesman.',
                status: 'Tentukan status akun salesman : Aktif(dapat login di aplikasi) atau Non Aktif(tidak dapat login di aplikasi).',
            },
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:item', null)
        }

        const handleOk = async () => {
            // validation form
            await formRef.value.validate()
                .catch(() => {})

            form.distributors = []

            form.distributor.forEach(item => {
                form.distributors.push({
                    vendor_id: item,
                })
            })

            if (!form.id) {
                form.post(state.endpoint.current)
                    .then(({ data }) => {
                        if (data === undefined) {
                            errorMessage.value = `Kode error 500, No response from server`
                            return
                        }

                        message.success('Berhasil menyimpan')

                        handleModalCancel()
                        emit('success', data)
                    })
            } else {
                delete form.password
                delete form.retype_password

                form.put(`${state.endpoint.current}/${form.id}`)
                    .then(({ data }) => {
                        if (data === undefined) {
                            errorMessage.value = `Kode error 500, No response from server`
                            return
                        }

                        message.success('Berhasil memperbarui')

                        handleModalCancel()
                        emit('success', data)
                    })
            }

        }

        const validateNopegAvailability = ((value) => {
            // Fungsi utama validasi
            const validate = async (value) => {
                try {
                    const { data } = await apiClient.post(state.endpoint.validate_nopeg, {
                        id: form.id,
                        data: value,
                    })
                    if (data.status === "success") {
                        return Promise.resolve()
                    } else {
                        return Promise.reject(data.message)
                    }
                } catch (error) {
                    return Promise.reject(
                        error.response?.data?.message || "Gagal memvalidasi.",
                    )
                }
            }

            // Fungsi debounce untuk menunda validasi
            const debouncedValidate = debounce((value, resolve, reject) => {
                validate(value).then(resolve).catch(reject)
            }, 300)

            // Fungsi wrapper untuk validasi
            return (value) => {
                return new Promise((resolve, reject) => {
                    // Selalu gunakan fungsi debounce
                    debouncedValidate(value, resolve, reject)
                })
            }
        })()

        const validateUsernameAvailability = ((value) => {
            // Fungsi utama validasi
            const validate = async (value) => {
                try {
                    const { data } = await apiClient.post(state.endpoint.validate_username, {
                        id: form.id,
                        data: value,
                        name: form.fullname,
                    })
                    if (data.status === "success") {
                        return Promise.resolve()
                    } else {
                        return Promise.reject(data.message)
                    }
                } catch (error) {
                    return Promise.reject(
                        error.response?.data?.message || "Gagal memvalidasi.",
                    )
                }
            }

            // Fungsi debounce untuk menunda validasi
            const debouncedValidate = debounce((value, resolve, reject) => {
                validate(value).then(resolve).catch(reject)
            }, 300)

            // Fungsi wrapper untuk validasi
            return (value) => {
                return new Promise((resolve, reject) => {
                    // Selalu gunakan fungsi debounce
                    debouncedValidate(value, resolve, reject)
                })
            }
        })()

        const validateEmailAvailability = ((value) => {
            // Fungsi utama validasi
            const validate = async (value) => {
                try {
                    const { data } = await apiClient.post(state.endpoint.validate_email, {
                        id: form.id,
                        data: value,
                    })
                    if (data.status === "success") {
                        return Promise.resolve()
                    } else {
                        return Promise.reject(data.message)
                    }
                } catch (error) {
                    return Promise.reject(
                        error.response?.data?.message || "Gagal memvalidasi.",
                    )
                }
            }

            // Fungsi debounce untuk menunda validasi
            const debouncedValidate = debounce((value, resolve, reject) => {
                validate(value).then(resolve).catch(reject)
            }, 300)

            // Fungsi wrapper untuk validasi
            return (value) => {
                return new Promise((resolve, reject) => {
                    // Selalu gunakan fungsi debounce
                    debouncedValidate(value, resolve, reject)
                })
            }
        })()
        
        // Render function to use LabelWithTooltip
        const renderCustomLabel = (label, tooltip) => {
            return <LabelWithTooltip label={label} tooltip={tooltip} />
        }

        // handle func vue
        onMounted(() => {
            if (!props.item.readOnly) {
                state.title = `${!form.id ? 'Tambah' : 'Edit'} - Master Data Salesman`
            }

            if (!form.id) {
                form.reset()
            } else {
                form.nopeg = props.item.nopeg
                form.fullname = props.item.fullname
                form.username = props.item.username
                form.email = props.item.email
                form.phone = props.item.phone
                form.tso_id = props.item.tso_id
                form.active = props.item.active ? 1 : 0
            }

            // default active only role ROLE_TSO
            if (hasRoles([ROLE_TSO])) {
                form.active = 1
            }
        })

        return {
            formRef,
            handleOk,
            form,
            state,
            handleModalCancel,
            renderCustomLabel,
            // only roles variable
            hasRoles,
            ROLE_ADMIN_BK,
            ROLE_TSO,
            ROLE_TIM_CRM,
            ROLE_DISTRIBUTOR,
            ROLE_HELPDESK,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/scss/form.scss'
</style>
